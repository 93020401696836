import currency from "currency.js"
import { currencySymbol, currencyPrecision } from "../assets/js/branding";
import i18n from "i18next";

export const formatAmount = (amount, productCurrency) => {
  
  let currencySettings = getCurrencySettings(productCurrency);

  return currency(amount, {
    fromCents: true,
    precision: currencySettings.precision, 
    symbol: currencySettings.symbol,
    decimal: i18n.t("number.decimal"),
    separator: i18n.t("number.separator"),
    pattern: i18n.t("currency.pattern"),
    negativePattern: i18n.t("currency.pattern_negative") }
  ).format()
}

function getCurrencySettings(currency) {
  var validCurrencySymbol = currencySymbol;
  var validCurrencyPrecision = currencyPrecision;
  if (currency) {
    switch (currency.toLowerCase()) {
      case "eur":
        validCurrencySymbol = "€";
        validCurrencyPrecision = 2;
        break;
      case "gbp":
        validCurrencySymbol = "£";
        validCurrencyPrecision = 2;
        break;
      case "usd":
        validCurrencySymbol = "$";
        validCurrencyPrecision = 2;
        break;
      default:
        break;
    }
  }
  return {
    symbol: validCurrencySymbol,
    precision: validCurrencyPrecision
  }
}