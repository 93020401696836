/* Currency */
export const currencyPrecision = 2;
export const currencySymbol = "€";

/* Product features */
export const unitTemperature = "°C";

/* Languages */
export const fallbackLng = ["en","es"];
export const supportedLngs = ["en","es"];
export const languageSelectionAllowed = false;

/* Config */
export const databaseURL = "https://greensystemscatalog-default-rtdb.europe-west1.firebasedatabase.app/"