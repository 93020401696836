import React from 'react'
import { useContext } from 'react';
import { StatusContext } from '../../context/StatusContext';
import { useNavigate } from "react-router-dom";
import { t } from 'i18next';

const Header = (props) => {

  const context = React.useContext(StatusContext);
  const navigate = useNavigate();

  let back = props.destinationBack || props.onGoBack;
  let done = props.destinationDone || props.onDone;
  let cart = props.destinationCart;
  let download = props.onDownload;

  function goHome(e) {
    e.preventDefault();
    context.setSelectedBrand(null);
    navigate("/");
  }

  function goBack(e) {
    e.preventDefault();
    if (props.onGoBack) {
      props.onGoBack();
    } else {
      navigate(props.destinationBack || "/");
    }
  }

  function onDone(e) {
    e.preventDefault();
    if (props.onDone) {
      props.onDone();
    } else {
      navigate(props.destinationDone || "/");
    }
  }

  function onCartClick(e) {
      e.preventDefault();
      navigate("/cart");
  }

  function onDownloadClick(e) {
      e.preventDefault();
      if (props.onDownload) {
        props.onDownload();
      }
  }

  function onShareEmailClick(e) {
      e.preventDefault();
      if (props.onShareEmail) {
        props.onShareEmail();
      }
  }

  const totalUnitsInCart = context.totalUnitsInCart();

  return (
    <div className="header">
      <button className="logo" onClick={ e => goHome(e) }/>
      <div className="spacing"/>
      <div className="title-container">
        { back && <button className="ic-back" onClick={ e => goBack(e) }/> }
        { !back && <button className="clear disabled"/> }
        <p>{props.title}</p>
        { done && <button className="ic-done" onClick={ e => onDone(e) }/> }
        { !done && <button className="clear disabled"/> }
      </div>
      <div className="spacing"/>
      { cart && <button className="ic-cart" onClick={ e => onCartClick(e) }><span>{ totalUnitsInCart < 100 ? (totalUnitsInCart > 0 ? totalUnitsInCart : "") : "99+" }</span></button> }
      { download && <button className={`ic-download${totalUnitsInCart > 0 ? '' : ' disabled'}`} onClick={ e => onDownloadClick(e) }/> }
      {/* { download && <button className="ic-share" onClick={ e => onDownloadClick(e) }/> } */}
      {/* { download && <div className='dropdown'>
        <div className='dropdown-content'>
          <a onClick={ e => onDownloadClick(e) }><p className='material-icons-container'><span className='material-icons md-24 main-color'>download</span>&nbsp;{ t('download') }</p></a>
          <a onClick={ e => onShareEmailClick(e) }><p className='material-icons-container'><span className='material-icons md-24 main-color'>mail</span>&nbsp;{ t('send_email') }</p></a>
        </div>
      </div> } */}
      { !cart && !download && <button className="ic-cart clear disabled"/> }
    </div>
  )
}

export default Header