import React from 'react'
import { StatusContext } from '../../context/StatusContext';
import { withTranslation } from 'react-i18next';
import { getAuth } from "firebase/auth";
import { getDatabase, ref, get } from "firebase/database";
import * as Constants from './../../assets/js/constants';
import * as Branding from './../../assets/js/branding';
import Header from '../header/Header';
import ruler from "../../assets/icons/ic_ruler.svg"
import temperature from "../../assets/icons/ic_temperature.svg"
import temperatureRange from "../../assets/icons/ic_temperature_range.svg"
import eco from "../../assets/icons/ic_eco.svg"
import sell from "../../assets/icons/ic_sell.svg"
import brandingArea from "../../assets/icons/ic_branding_area.svg"
import delivery from "../../assets/icons/ic_delivery.svg"
import Footer from '../footer/Footer'
import * as MoneyFormatter from '../../utils/MoneyFormatter'
import { withNavigation } from '../../utils/Navigation';
import Lottie from "lottie-web/build/player/lottie_light";
import emptyCartAnimationData from '../../assets/lotties/loading.json';
import emptyCatalogAnimationData from '../../assets/lotties/empty_catalog.json';
import ContentBetweenHeaderFooter from '../content/ContentBetweenHeaderFooter';
import { isExw } from '../../utils/BusinessUtils';

class Catalog extends React.Component {

    static contextType = StatusContext;
  
    constructor(props) {
        super(props);
        this.state = {
            error: false
        };

        // Initialize Realtime Database and get a reference to the service
        const db = getDatabase();
        get(ref(db, Constants.tableCatalog))
            .then((snapshot) => {
                const data = snapshot.val();
                // console.log(data);

                this.context.setCatalog(data);
                this.setState({
                    error: false
                })
            })
            .catch((err) => {
                console.error(err);
                this.context.setCatalog(null);
                this.setState({
                    error: true
                })
            });
    }

    componentDidMount() {
        Lottie.loadAnimation({
            container: document.querySelector("#animationLoading"),
            animationData: emptyCartAnimationData,
            loop: false,
            autoplay: true
        });
    }

    componentDidUpdate() {
        if (this.context.catalog === null || this.context.catalog.length === 0) {
            Lottie.loadAnimation({
                container: document.querySelector("#animation"),
                animationData: emptyCatalogAnimationData,
                loop: true,
                autoplay: true
            });
        }
    }

    itemBrandSelected(e, brand) {
        if (e) {
            e.preventDefault();
        }
        this.context.setSelectedBrand(brand)
        this.contentDiv.scrollTo(0, 0);
    }

    itemStockUpdate(e, id, amount) {
        e.preventDefault();
        e.stopPropagation();
        if (amount > 0) {
            this.context.addToCart(id, amount);
        } else {
            this.context.removeFromCart(id, -amount);
        }
    }

    filterChanged(e, filter, value) {
        if (e.target.checked) {
            this.context.addFilter(filter, value);
        } else {
            this.context.removeFilter(filter, value);
        }
    }

    openProduct(e, id) {
        e.preventDefault();
        this.props.navigate(`/products/${id}`);
    }
  
    render() {
        const auth = getAuth();
        if (!auth || !auth.currentUser) {
            this.props.navigate("/login");
        }

        const { t } = this.props;

        // if (this.state.error) {
        //     return <h1>Error!!!</h1>;
        // }
        if (this.context.catalog === null && !this.state.error) {
            return <>
                <Header title={ t('catalog') } />
                <ContentBetweenHeaderFooter>
                    <div id="animationLoading"/>
                </ContentBetweenHeaderFooter>
                <Footer/>
            </>;
        }

        const types = [];
        const coolings = [];
        const energeticLabels = [];
        const itemsForBrand = this.context.selectedBrand ? this.context.catalog.filter(item => {
            if (this.context.selectedBrand === item.brand) {
                if (item.type !== undefined && item.type !== "" && !types.includes(item.type)) {
                    types.push(item.type);
                }
                if (item.cooling !== undefined && item.cooling !== "" && !coolings.includes(item.cooling)) {
                    coolings.push(item.cooling);
                }
                if (item.energetic_label !== undefined && item.energetic_label !== "" && !energeticLabels.includes(item.energetic_label)) {
                    energeticLabels.push(item.energetic_label);
                }
                return !(this.context.shouldFilter("type", item.type) ||
                 this.context.shouldFilter("cooling", item.cooling) ||
                 this.context.shouldFilter("energeticLabel", item.energetic_label));
            }
            return false;
        }) : [];

        if (this.context.catalog === null || this.context.catalog.length === 0) {
            return <>
                <Header title={ t('catalog') }/>
                <div className='content-between-header-footer'>
                    <div className='empty-content'>
                        <h1>{t('error_no_items_in_catalog')}</h1>
                        <div id="animation"/>
                    </div>
                </div>
                <Footer/>
            </>
        }

        if (this.context.selectedBrand) {
            const isExwSelected = isExw(this.context.selectedCountry);
            return  <>
                <Header
                    title={ this.context.selectedBrand }
                    onGoBack={ () => {
                        this.context.clearFilters();
                        this.itemBrandSelected(null, null);
                    } }
                    destinationCart={ true }/>
                <ContentBetweenHeaderFooter ref={(div) => { this.contentDiv = div }}>
                    <div className='filters'>
                        <div className='componentWrapper'>
                            <p className='componentHeader material-icons-container'><span className='material-icons md-24'>straighten</span></p>
                            <form>
                                { types.map(type => <label htmlFor={ type }><input type="checkbox"
                                    id={ type }
                                    name={ type }
                                    value={ type }
                                    checked={ this.context.isFilter("type", type) }
                                    onChange={ (e) => this.filterChanged(e, "type", type) }/> { type }</label>) }
                            </form>
                        </div>
                        <div className='spacing'/>
                        <div className='componentWrapper'>
                            <p className='componentHeader material-icons-container'><span className='material-icons md-24'>device_thermostat</span></p>
                            <form>
                                { coolings.map(cooling => <label htmlFor={ cooling }><input type="checkbox"
                                    id={ cooling }
                                    name={ cooling }
                                    value={ cooling }
                                    checked={ this.context.isFilter("cooling", cooling) }
                                    onChange={ (e) => this.filterChanged(e, "cooling", cooling) }/> { cooling }</label>) }
                            </form>
                        </div>
                        {/* <div className='spacing'/>
                        <div className='componentWrapper'>
                            <p className='componentHeader material-icons-container'><span className='material-icons md-24'>temp_preferences_eco</span></p>
                            <form>
                                { energeticLabels.map(energeticLabel => <label htmlFor={ energeticLabel }><input type="checkbox"
                                    id={ energeticLabel }
                                    name={ energeticLabel }
                                    value={ energeticLabel }
                                    checked={ this.context.isFilter("energeticLabel", energeticLabel) }
                                    onChange={ (e) => this.filterChanged(e, "energeticLabel", energeticLabel) }/> { energeticLabel }</label>) }
                            </form>
                        </div> */}
                    </div>
                    <div className='catalog'>
                        { itemsForBrand.map(item => {
                            const energeticLabel = item.energetic_label ? item.energetic_label : "-";
                            const unitsInCart = this.context.unitsInCart(item.id);
                            const hasTemperatureRange = item.temp_min !== '' && item.temp_max !== '' && item.temp_min !== undefined && item.temp_max !== undefined;
                            
                            return <div className='catalog-item' onClick={ e => this.openProduct(e, item.id) }>
                                <img src={ '/coolers/' + item.image }></img>
                                <div className='catalog-item-content'>
                                    <p className='title'>{ item.name }</p>
                                    <div>
                                        <img src={ ruler }/>
                                        <p>{ item.type ? item.type : "-" }</p>
                                    </div>
                                    <div>
                                        <img src={ temperature }/>
                                        <p>{ item.cooling ? item.cooling : "-" }</p>
                                    </div>
                                    <div>
                                        <img src={ temperatureRange }/>
                                        <p>{ hasTemperatureRange ? (`${item.temp_min}${Branding.unitTemperature} ${t('range_divider_to')} ${item.temp_max}${Branding.unitTemperature}`) : "-" }</p>
                                    </div>
                                    <div>
                                        <img src={ eco }/>
                                        <p className={ 'eco-' + energeticLabel.toLowerCase() }>{ energeticLabel.toUpperCase() }</p>
                                    </div>
                                    <div>
                                        <img src={ brandingArea }/>
                                        { item.branding_area && <a href={ '/branding_areas/' + item.branding_area } target="_blank" rel="noopener noreferrer" onClick={ e => e.stopPropagation() }>{ t('branding_area') }</a> }
                                        { !item.branding_area && <p>-</p> }
                                    </div>
                                    <div>
                                        <img src={ delivery }/>
                                        <p>{ item.qty_per_trailer ? item.qty_per_trailer : "-" }</p>
                                    </div>
                                    <div>
                                        <img src={ sell }/>
                                        { isExwSelected && <p>{ MoneyFormatter.formatAmount(item['exw']) + ' (' + t('exw') + ')' }</p> }
                                        { !isExwSelected && <p>{ MoneyFormatter.formatAmount(item['ddp_' + this.context.selectedCountry]) + ' (' + t('ddp') + '*)' }</p> }
                                    </div>
                                    <div className='cart-stock'>
                                        <button className={ 'ic-remove' + (unitsInCart !== 0 ? '' : ' disabled') } onClick={ (e) => this.itemStockUpdate(e, item.id, -1) }/>
                                        <span>{ unitsInCart }</span>
                                        <button className='ic-add' onClick={ (e) => this.itemStockUpdate(e, item.id, 1) }/>
                                    </div>
                                </div>
                            </div>
                        }) }
                    </div>
                    { !isExwSelected && <p className="disclaimer">{'* ' + t('ddp_price_disclaimer')}</p> }
                </ContentBetweenHeaderFooter>
                <Footer/>
            </>
        }

        // Display brands
        return  <>
            <Header title={ t('catalog') } destinationCart={ true }/>
            <div className='content-between-header-footer'>
                <div className='catalog'>
                    { Object.keys(Constants.brands).map(brand => {
                        return <div className='catalog-item' onClick={ e => this.itemBrandSelected(e, brand) }>
                            <img src={ '/brands/' + Constants.brands[brand] }></img>
                            <div className='catalog-item-content'>
                                <p className='title'>{ brand }</p>
                            </div>
                        </div>
                    }) }
                </div>
            </div>
            <Footer/>
        </>
    }
}
  
export default withNavigation(withTranslation()(Catalog))