import React, { Component } from 'react';
import PropTypes from 'prop-types'

export default class ContentBetweenHeaderFooter extends Component {
  static propTypes = {
    children: PropTypes.node
  }

  render () {
    return (
      <div className='content-between-header-footer'>
        {this.props.children}
      </div>
    );
  }
}