import React from 'react';
import { StatusContext } from './context/StatusContext';
import { Helmet } from "react-helmet";
import './i18n';
import { withTranslation } from 'react-i18next';
import Main from './Main';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { withRouter } from "./utils/Router"
import { getDatabase } from "firebase/database";
import * as Branding from './assets/js/branding';
import * as Constants from './assets/js/constants';

class App extends React.Component {

  firebaseConfig = {
    apiKey: "AIzaSyASCOup7CJK4dRWt927nNFV3aIuf2SKVGI",
    authDomain: "greensystemscatalog-81a33.firebaseapp.com",
    projectId: "greensystemscatalog",
    storageBucket: "greensystemscatalog.appspot.com",
    messagingSenderId: "323499197627",
    appId: "1:323499197627:web:32eb6ae5b9f31c3367d59e",
    measurementId: "G-F23PJHBHQE"
  };
  app;
  analytics;
  database;

  constructor(props) {
    super(props);

    this.state = {
      catalog: null,
      cart: {},
      filters: {},
      selectedCountry: Constants.countries[0],
      selectedBrand: null
    }

    if (!App.app) {
      // Initialize Firebase+Analytics
      App.app = initializeApp({
        apiKey: "AIzaSyASCOup7CJK4dRWt927nNFV3aIuf2SKVGI",
        authDomain: "greensystemscatalog-81a33.firebaseapp.com",
        projectId: "greensystemscatalog",
        storageBucket: "greensystemscatalog.appspot.com",
        messagingSenderId: "323499197627",
        appId: "1:323499197627:web:32eb6ae5b9f31c3367d59e",
        measurementId: "G-F23PJHBHQE",
        databaseURL: Branding.databaseURL,
      });
      App.analytics = getAnalytics(App.app);

      // Initialize Realtime Database and get a reference to the service
      App.database = getDatabase(App.app);
    }
  }

  render() {
    const { t } = this.props;

    return <StatusContext.Provider value={{
      catalog: this.state.catalog,
      setCatalog: (catalog) => this.setState(state => ({
        catalog: catalog
      })),
      cart: this.state.cart,
      setCart: (cart) => this.setState(state => ({
        cart: cart
      })),
      addToCart: (productId, amount) => {
        var cart = this.state.cart
        if (cart === undefined) {
          cart = {};
        }
        cart[productId] = (cart[productId] !== undefined ? cart[productId] : 0) + amount;
        this.setState(state => ({
          cart: cart
        }));
      },
      removeFromCart: (productId, amount) => {
        var cart = this.state.cart
        if (cart === undefined) {
          cart = {};
        }
        cart[productId] = (cart[productId] !== undefined ? cart[productId] : 0) - amount;
        if (cart[productId] <= 0) {
          delete cart[productId];
        }
        this.setState(state => ({
          cart: cart
        }));
      },
      unitsInCart: (productId) => {
        var cart = this.state.cart
        if (cart === undefined || cart[productId] === undefined) {
          return 0;
        }
        return cart[productId];
      },
      totalUnitsInCart: () => {
        var cart = this.state.cart
        if (cart === undefined || Object.keys(cart).length === 0) {
          return 0;
        }
        var count = 0;
        Object.keys(cart).forEach(key => count += cart[key]);
        return count;
      },
      isCartEmpty: () => {
        var cart = this.state.cart
        return cart === undefined || Object.keys(cart).length === 0;
      },
      clearCart: () => {
        this.setState(state => ({
          cart: {}
        }));
      },
      filters: this.state.filters,
      setFilters: (filters) => this.setState(state => ({
        filters: filters
      })),
      addFilter: (item, value) => {
        var filters = this.state.filters
        if (filters === undefined) {
          filters = {};
        }
        if (filters[item]) {
          if (!filters[item].includes(value)) {
            filters[item].push(value);
          }
        } else {
          filters[item] = [value];
        }
        this.setState(state => ({
          filters: filters
        }));
      },
      removeFilter: (item, value) => {
        var filters = this.state.filters
        if (filters === undefined) {
          filters = {};
        }
        if (filters[item]) {
          filters[item] = filters[item].filter(filterValue => {
            return filterValue !== value;
          });
          if (filters[item].length === 0) {
            delete filters[item];
          }
        }
        this.setState(state => ({
          filters: filters
        }));
      },
      shouldFilter: (item, value) => {
        var filters = this.state.filters
        if (filters === undefined || Object.keys(filters).length === 0 || !filters[item]) {
          return false;
        }
        return !filters[item].includes(value);
      },
      isFilter: (item, value) => {
        var filters = this.state.filters
        if (filters === undefined || Object.keys(filters).length === 0 || !filters[item]) {
          return false;
        }
        return filters[item].includes(value);
      },
      clearFilters: () => {
        this.setState(state => ({
          filters: {}
        }));
      },
      selectedCountry: this.state.selectedCountry,
      setSelectedCountry: (selectedCountry) => this.setState(state => ({
        selectedCountry: selectedCountry
      })),
      selectedBrand: this.state.selectedBrand,
      setSelectedBrand: (selectedBrand) => {
        this.setState(state => ({
          selectedBrand: selectedBrand
        }));
      },
      isClear: () => {
        return this.state.catalog === null && 
          (this.state.cart === undefined || Object.keys(this.state.cart).length === 0) &&
          this.state.selectedCountry === Constants.countries[0];
      },
      clear: () => {
        this.setState(state => ({
          catalog: null,
          cart: {},
          filters: {},
          selectedCountry: Constants.countries[0],
          selectedBrand: null
        }));
      }
    }}>
      <Helmet>
        <title>{t('helmet_title')}</title>
      </Helmet>
      <Main/>
    </StatusContext.Provider>
  }
}

export default withRouter(withTranslation()(App))