import React from 'react'
import { useTranslation } from 'react-i18next'
import { StatusContext } from '../../context/StatusContext'
import { useNavigate } from "react-router-dom";
import * as Constants from '../../assets/js/constants'
import { getAuth, signOut } from "firebase/auth";

const Footer = (props) => {

  const context = React.useContext(StatusContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onCountrySelected(e) {
    e.preventDefault();
    context.setSelectedCountry(e.target.value);
    if (props.onCountryChange) {
      props.onCountryChange();
    }
  }

  function logout(e) {
      e.preventDefault();
      const auth = getAuth();
      signOut(auth).then(() => {
        navigate("/");
      }).catch((error) => {
        // An error happened.
      });
  }

  const auth = getAuth();
  const loggedIn = auth && auth.currentUser

  return (
    <div className={'footer ' + props.className}>
        { loggedIn && <div><button onClick={ (e) => logout(e) }>{ t('logout') }</button></div> }
        { !loggedIn && <div/> }
        <div id='contact-info'>
            <p className='material-icons-container not-compact'>Anne Mailly&nbsp;&nbsp;&nbsp;&nbsp;<span className='material-icons md-16'>phone</span>&nbsp;<a href='tel:0034697681420'>+34697681420</a>&nbsp;&nbsp;&nbsp;&nbsp;<span className='material-icons md-16'>email</span>&nbsp;<a href='mailto:anne.mailly@greensystems.com.es'>anne.mailly@greensystems.com.es</a></p>
            <p className='material-icons-container not-compact'><span className='material-icons md-16'>location_on</span>Crta. Monturque-Cabra (Ctra A-342) KM 7,8&nbsp;&nbsp;&nbsp;&nbsp;14940 Cabra (Córdoba) - SPAIN</p>
            <p className='material-icons-container compact'><span>Anne Mailly</span>&nbsp;&nbsp;&nbsp;&nbsp;<a href='tel:0034697681420'><span className='material-icons md-24'>phone</span></a>&nbsp;&nbsp;&nbsp;&nbsp;<a href='mailto:anne.mailly@greensystems.com.es'><span className='material-icons md-24'>email</span></a>&nbsp;&nbsp;&nbsp;&nbsp;<a href='https://goo.gl/maps/v5gQQe8dcUJo7XUU8'><span className='material-icons md-24'>location_on</span></a></p>
            <p>{ '© Green Systems ' + new Date().getFullYear() }</p>
        </div>
        { !loggedIn && <div/> }
        { loggedIn &&  <div id='country-selection'>
            <img src={ "/flags/" + Constants.countriesIso[Constants.countries.indexOf(context.selectedCountry)] + ".svg"} width="24"></img>
            <select name="countries" id="countries" value={ context.selectedCountry } onChange={ (e) => onCountrySelected(e) }>
                { Constants.countries.map(country => {
                    return <option value={country}>{ t('country_' + country) }</option>
                })}
            </select>
        </div> }
    </div>
    // <div className={ `footer ${classForType}` }>
    //   { icon }
    //   { props.message && <p>{ props.message }</p> }
    // </div>
  )
}

export default Footer