import React from 'react'
import { StatusContext } from '../../context/StatusContext';
import { withTranslation } from 'react-i18next';
import { getAuth } from "firebase/auth";
import { getDatabase, ref, get } from "firebase/database";
import * as Constants from './../../assets/js/constants';
import Header from '../header/Header';
import Footer from '../footer/Footer'
import * as MoneyFormatter from '../../utils/MoneyFormatter'
import { withNavigation } from '../../utils/Navigation';
import Lottie from "lottie-web/build/player/lottie_light";
import emptyCartAnimationData from '../../assets/lotties/empty_cart.json';
import ExcellentExport from 'excellentexport';
import { isExw } from '../../utils/BusinessUtils';

class Cart extends React.Component {

    static contextType = StatusContext;
  
    constructor(props) {
        super(props);
        this.state = {
            catalog: null,
            error: false,
            selectedBrand: null
        };

        // Initialize Realtime Database and get a reference to the service
        const db = getDatabase();
        get(ref(db, Constants.tableCatalog))
            .then((snapshot) => {
                const data = snapshot.val();
                // console.log(data);

                this.setState({
                    catalog: snapshot.val(),
                    error: false
                })
            })
            .catch((err) => {
                console.error(err);
                this.setState({
                    catalog: null,
                    error: true
                })
            });
    }

    componentDidUpdate() {
        if (this.context.isCartEmpty()) {
            Lottie.loadAnimation({
                container: document.querySelector("#animation"),
                animationData: emptyCartAnimationData,
                loop: false,
                autoplay: true
            });
        }
    }

    itemStockUpdate(e, id, amount) {
        e.preventDefault();
        e.stopPropagation();
        if (amount > 0) {
            this.context.addToCart(id, amount);
        } else {
            this.context.removeFromCart(id, -amount);
        }
    }

    clearCart(e) {
        e.preventDefault();
        this.context.clearCart();
        this.loadAnimation();
    }

    download() {
        const { t } = this.props;

        const isExwSelected = isExw(this.context.selectedCountry);
        const date = new Date();
        const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
        const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
        const filename = `${year}${month}${day} Green Systems Catalog - ${t('country_' + this.context.selectedCountry)}`;
        var total = 0;
        var content = [];
        content.push([
            t('product'),
            t('quantity'),
            isExwSelected ? t('exw_price') : (t('ddp_price') + '*'),
            t('total')
        ]);
        Object.keys(this.context.cart).forEach(id => {
            const product = this.context.catalog.find(product => {
                return product.id == id;
            });
            const price = isExwSelected ? product['exw'] : product['ddp_' + this.context.selectedCountry]
            total += price * this.context.cart[id];
            content.push([
                product.name,
                this.context.cart[id],
                MoneyFormatter.formatAmount(price),
                MoneyFormatter.formatAmount(price * this.context.cart[id])
            ]);
        });
        content.push([t('total'), "", "", MoneyFormatter.formatAmount(total)]);
        if (!isExwSelected) {
            content.push([]);
            content.push(["* " + t('ddp_price_disclaimer')]);
        }

        ExcellentExport.convert({
            openAsDownload: true,
            format: 'xlsx',
            filename: filename,
            rtl: false
        }, [{
            name: t('country_' + this.context.selectedCountry),
            rtl: false,
            from: {
                array: content
            }
        }]);
    }

    shareEmail() {
        // Not implemented
    }
  
    render() {
        const auth = getAuth();
        if (!auth || !auth.currentUser) {
            this.props.navigate("/login");
        }

        const { t } = this.props;

        if (this.state.error) {
            return <h1>Error!!!</h1>;
        }

        const isExwSelected = isExw(this.context.selectedCountry);
        var total = 0;
        return  <>
            <Header title={ t('cart') } onGoBack={ () => this.props.navigate(-1) } onDownload={ () => this.download() } onShareEmail={ () => this.shareEmail() }/>
            <div className='content-between-header-footer'>
                { this.context.isCartEmpty() && <div className='empty-content'>
                    <h1>{t('error_no_items_in_cart')}</h1>
                    <div id="animation"/>
                </div> }
                { !this.context.isCartEmpty() && <>
                    <table className="cart"><tbody>
                        <tr>
                            <th>{t('product')}</th>
                            <th>{t('quantity')}</th>
                            { isExwSelected && <th>{t('exw_price')}</th> }
                            { !isExwSelected && <th>{t('ddp_price') + '*'}</th> }
                            <th>{t('total')}</th>
                            <th/>
                        </tr>
                        { Object.keys(this.context.cart).map(id => {
                            const product = this.context.catalog.find(product => {
                                return product.id == id;
                            });
                            const unitsInCart = this.context.cart[id];
                            const price = (isExwSelected ? product['exw'] : product['ddp_' + this.context.selectedCountry]);
                            total += price * unitsInCart;
                            return <tr>
                                <td>{product.name}</td>
                                {/* <td>{this.context.cart[id]}</td> */}
                                <td><div className='cart-stock'>
                                        <button className={ 'ic-remove' + (unitsInCart !== 0 ? '' : ' disabled') } onClick={ (e) => this.itemStockUpdate(e, product.id, -1) }/>
                                        <span>{ unitsInCart }</span>
                                        <button className='ic-add' onClick={ (e) => this.itemStockUpdate(e, product.id, 1) }/>
                                </div></td>
                                <td>{MoneyFormatter.formatAmount(price) }</td>
                                <td>{MoneyFormatter.formatAmount(price * unitsInCart) }</td>
                                <td><div><button className='ic-delete' onClick={ (e) => this.itemStockUpdate(e, product.id, -unitsInCart) }/></div></td>
                            </tr>
                        }) }
                        <tr className="total">
                            <td colspan="3">{t('total')}</td>
                            <td>{MoneyFormatter.formatAmount(total) }</td>
                            <td><div><button className='ic-delete' onClick={ (e) => this.clearCart(e) }/></div></td>
                        </tr>
                    </tbody></table>
                    { !isExwSelected && <p className="disclaimer">{'* ' + t('ddp_price_disclaimer')}</p> }
                </> }
            </div>
            <Footer/>
        </>
    }
}
  
export default withNavigation(withTranslation()(Cart))