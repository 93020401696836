/* Database */
export const tableCatalog = "catalog";
export const countries = [
    "austria",
    "belgium",
    "denmark",
    "finland",
    "france",
    "germany",
    "great_britain",
    "italy",
    "luxemburg",
    "netherlands",
    "norway",
    "poland",
    "sweden",
    "switzerland",
    "other"
];
export const countriesIso = [
    "at",
    "be",
    "dk",
    "fi",
    "fr",
    "de",
    "gb",
    "it",
    "lu",
    "nl",
    "no",
    "pl",
    "se",
    "ch",
    "world"
];
export const brands = {
    "MultiBrand": "MULTIBRAND.jpg",
    "Bud | Budweiser": "BUDWEISER.jpg",
    "Corona": "CORONA.jpg",
    "Leffe & La Legere": "LEFFE.jpg",
    "Stella": "STELLA_ARTOIS.jpg",
    "TripelK": "TRIPEL_KARMELIET.jpg",
    "Presidente": "PRESIDENTE.jpg",
    "Modelo": "MODELO.jpg",
    "Professional": "PROFESIONAL.jpg"
}