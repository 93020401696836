import React from 'react'
import { withTranslation } from 'react-i18next';
import { withNavigation } from '../../utils/Navigation';
import { withRouter } from '../../utils/Router';
import { StatusContext } from '../../context/StatusContext';
import Header from '../header/Header';
import ContentBetweenHeaderFooter from '../content/ContentBetweenHeaderFooter';
import Footer from '../footer/Footer';
import ruler from "../../assets/icons/ic_ruler.svg"
import temperature from "../../assets/icons/ic_temperature.svg"
import temperatureRange from "../../assets/icons/ic_temperature_range.svg"
import eco from "../../assets/icons/ic_eco.svg"
import delivery from "../../assets/icons/ic_delivery.svg"
import sell from "../../assets/icons/ic_sell.svg"
import brandingArea from "../../assets/icons/ic_branding_area.svg"
import * as Branding from './../../assets/js/branding';
import * as MoneyFormatter from '../../utils/MoneyFormatter'
import Lottie from "lottie-web/build/player/lottie_light";
import emptyCatalogAnimationData from '../../assets/lotties/empty_catalog.json';
import { isExw } from '../../utils/BusinessUtils';

class Product extends React.Component {

    static contextType = StatusContext;

    constructor(props) {
        super(props);
        this.state = {
            productId: props.router.params.productId
        };
    }

    componentDidMount() {
        Lottie.loadAnimation({
            container: document.querySelector("#animation"),
            animationData: emptyCatalogAnimationData,
            loop: true,
            autoplay: true
        });
    }

    itemStockUpdate(e, id, amount) {
        e.preventDefault();
        e.stopPropagation();
        if (amount > 0) {
            this.context.addToCart(id, amount);
        } else {
            this.context.removeFromCart(id, -amount);
        }
    }

    render() {
        const { t } = this.props;
        const product = this.context?.catalog?.find(product => {
            return product.id == this.state.productId;
        });

        if (product === undefined) {
            return <>
                <Header
                    title={ t('not_found') }
                    onGoBack={ () => {
                        this.props.navigate(-1);
                    } }/>
                <div className='content-between-header-footer'>
                    <div className='empty-content'>
                        <h1>{t('error_product_not_found')}</h1>
                        <div id="animation"/>
                    </div>
                </div>
                <Footer/>
            </>
        }

        const energeticLabel = product.energetic_label !== undefined ? product.energetic_label : "-";
        const unitsInCart = this.context.unitsInCart(product.id);
        const isExwSelected = isExw(this.context.selectedCountry);
        const hasTemperatureRange = product.temp_min !== '' && product.temp_max !== '' && product.temp_min !== undefined && product.temp_max !== undefined;

        return <>
            <Header
                title={ product.brand }
                onGoBack={ () => {
                    this.props.navigate(-1);
                } }
                destinationCart={ true }/>
            <ContentBetweenHeaderFooter>
                <div className='product-container'>
                    <div className='catalog-item inactive'>
                        <img src={ '/coolers/' + product.image }></img>
                        <div className='catalog-item-content'>
                            <p className='title'>{ product.name }</p>
                            <div>
                                <img src={ ruler }/>
                                <p>{ product.type ? product.type : "-" }</p>
                            </div>
                            <div>
                                <img src={ temperature }/>
                                <p>{ product.cooling ? product.cooling : "-" }</p>
                            </div>
                            <div>
                                <img src={ temperatureRange }/>
                                <p>{ hasTemperatureRange ? (`${product.temp_min}${Branding.unitTemperature} ${t('range_divider_to')} ${product.temp_max}${Branding.unitTemperature}`) : "-" }</p>
                            </div>
                            <div>
                                <img src={ eco }/>
                                <p className={ 'eco-' + energeticLabel.toLowerCase() }>{ energeticLabel.toUpperCase() }</p>
                            </div>
                            <div>
                                <img src={ brandingArea }/>
                                { product.branding_area && <a href={ '/branding_areas/' + product.branding_area } target="_blank" rel="noopener noreferrer" onClick={ e => e.stopPropagation() }>{ t('branding_area') }</a> }
                                { !product.branding_area && <p>-</p> }
                            </div>
                            <div>
                                <img src={ delivery }/>
                                <p>{ product.qty_per_trailer ? product.qty_per_trailer : "-" }</p>
                            </div>
                            <div>
                                <img src={ sell }/>
                                { isExwSelected && <p>{ MoneyFormatter.formatAmount(product['exw']) + ' (' + t('exw') + ')' }</p> }
                                { !isExwSelected && <p>{ MoneyFormatter.formatAmount(product['ddp_' + this.context.selectedCountry]) + ' (' + t('ddp') + '*)' }</p> }
                            </div>
                            <div className='cart-stock'>
                                <button className={ 'ic-remove' + (unitsInCart !== 0 ? '' : ' disabled') } onClick={ (e) => this.itemStockUpdate(e, product.id, -1) }/>
                                <span>{ unitsInCart }</span>
                                <button className='ic-add' onClick={ (e) => this.itemStockUpdate(e, product.id, 1) }/>
                            </div>
                        </div>
                    </div>
                </div>
                { !isExwSelected && <p className="disclaimer">{'* ' + t('ddp_price_disclaimer')}</p> }
            </ContentBetweenHeaderFooter>
            <Footer/>
        </>;
    }
}

export default withRouter(withNavigation(withTranslation()(Product)))