import React, { useState } from 'react'
import { StatusContext } from '../../context/StatusContext';
import { useTranslation } from 'react-i18next'
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Footer from '../footer/Footer';
import logo from "./../../assets/images/logo_greensystems.png"

const Login = () => {

    const { t } = useTranslation();
    const context = React.useContext(StatusContext);
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    if (!context.isClear()) {
        context.clear();
    }

    function submitLogin(event) {
        event.preventDefault();
        resetMessages(event);

        const auth = getAuth();
        signInWithEmailAndPassword(auth, username, password)
            .then((userCredential) => {
                // console.log("Signed in: " + userCredential.user.email);
                // const user = userCredential.user;
                navigate("/");
            })
            .catch((error) => {
                // console.log(error);
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // setError(errorMessage);
                setError(t('error_login'));
            });
    }

    function forgotPassword(event) {
        event.preventDefault();
        resetMessages(event);

        const auth = getAuth();
        sendPasswordResetEmail(auth, username)
        .then(() => {
            // Password reset email sent!
            setSuccess(t('password_reset_success_message'));
        })
        .catch((error) => {
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // setError(errorMessage);
            setError(t('error_enter_email'));
        });
    }

    function resetMessages(event) {
        event.preventDefault();
        setSuccess('');
        setError('');
    }

    return (
        <>
            <div className='content-over-footer'>
                <div id='container-login' onSubmit={ submitLogin }>
                    <img className="logo" src={ logo }/>
                    <form name="login">
                        <label htmlFor='uname'>{ t('username') }</label>
                        <input 
                            type='email'
                            placeholder={ t('enter_your_username') }
                            value={ username }
                            onChange={ e => setUsername(e.target.value) }
                            name='uname'
                            required
                        />
                        
                        <label htmlFor='psw'>{ t('password') }</label>
                        <input
                            type='password'
                            placeholder={ t('enter_your_password') }
                            value={ password }
                            onChange={ e => setPassword(e.target.value) }
                            name='psw'
                            required
                        />

                        <button type='submit'>{ t('login') }</button>
                    </form>
                    <a onClick={ e => forgotPassword(e) }>{ t('reset_password') }</a>
                </div>
                { error != '' && <p className='message-error' onClick={ e => resetMessages(e)}>{ error }</p> }
                { success != '' && <p className='message-success' onClick={ e => resetMessages(e)}>{ success }</p> }
            </div>
            <Footer className="tiny"/>
        </>
    )
}

export default Login