import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './components/login/Login'
import Catalog from './components/catalog/Catalog'
import Product from './components/product/Product'
import Cart from './components/cart/Cart'

const Main = () => {
  return (
    <Routes> {/* The Routes decides which component to show based on the current URL.*/}
        <Route exact path='/login' element={ <Login/> }></Route>
        <Route exact path='/' element={ <Catalog/> }></Route>
        <Route exact path='/cart' element={ <Cart/> }></Route>
        <Route path='/products/:productId' element={ <Product/> }></Route>
    </Routes>
  );
}

export default Main;