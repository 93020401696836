import React from "react";

export const StatusContext = React.createContext({
  catalog: null,
  setCatalog: (catalog) => {},
  cart: {},
  setCart: () => {},
  addToCart: (productId, amount) => {},
  removeFromCart: (productId, amount) => {},
  unitsInCart: (productId) => {},
  totalUnitsInCart: () => {},
  isCartEmpty: () => {},
  clearCart: () => {},
  filters: {},
  setFilters: () => {},
  addFilter: (item, value) => {},
  removeFilter: (item, value) => {},
  shouldFilter: (item, value) => {},
  isFilter: (item, value) => {},
  clearFilters: () => {},
  selectedCountry: null,
  setSelectedCountry: (selectedCountry) => {},
  selectedBrand: null,
  setSelectedBrand: (selectedBrand) => {},
  isClear: () => {},
  clear: () => {}
});