import { useNavigate } from 'react-router-dom';

export function withNavigation(Component) {
    function ComponentWithNavigateProp(props) {
        const navigate = useNavigate();
        return (
        <Component
            {...props}
            navigate={navigate}
        />
        );
    }

    return ComponentWithNavigateProp;
}